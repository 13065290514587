<div class="header">
  <div class="logo logo-dark">
    <a routerLink="/dashborad" class="w-100 h-100 d-flex align-items-center justify-content-center py-2">
      <img src="assets/images/logo/login-logo.svg" alt="Logo" />
      <img class="logo-fold" src="assets/images/logo/logo-fold.png" alt="Logo" />
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i nz-icon [nzType]="isFolded ? 'menu-unfold' : 'menu-fold'" theme="outline"></i>
        </a>
      </li>
      <li class="mobile-toggle">
        <a (click)="toggleExpand()">
          <i nz-icon [nzType]="isExpand ? 'menu-fold' : 'menu-unfold'" theme="outline"></i>
        </a>
      </li>
    </ul>
    <ul class="nav-right">
      <li>
        <span class="p-h-10 pointer d-flex align-items-center  " nz-dropdown [nzDropdownMenu]="profile"
          [nzTrigger]="'click'" [nzPlacement]="'bottomRight'">

          <nz-avatar [nzIcon]="'user'" nzSrc=" "></nz-avatar>
          <div class="ml-2">
            <p class="m-b-0 text-dark font-weight-semibold">{{user?.user_name}}</p>
            <p class="m-b-0 opacity-07 " style="line-height: 1;">{{ user?.userRole }}</p>
          </div>

        </span>

        <nz-dropdown-menu #profile="nzDropdownMenu">
          <ul nz-menu class="p-b-15 p-t-20">
            <li class="p-h-20 p-b-15 m-b-10 border-bottom">
              <div class="d-flex m-r-50">
                <nz-avatar nzSize="large" [nzIcon]="'user'" nzSrc=" "></nz-avatar>
                <div class="m-l-10">
                  <p class="m-b-0 text-dark font-weight-semibold">{{user?.user_name}}</p>
                  <p class="m-b-0 opacity-07">{{ user?.userRole }}</p>
                </div>
              </div>
            </li>
            <!-- <li nz-menu-item>
                    <a class="p-v-5 d-flex align-items-center justify-content-between">
                        <div>
                            <i class="opacity-04 font-size-16" nz-icon nzType="user" theme="outline"></i>
                            <span class="m-l-10">Edit Profile</span>
                        </div>
                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                    </a>
                </li>
                <li nz-menu-item>
                    <a class="p-v-5 d-flex align-items-center justify-content-between">
                        <div>
                            <i class="opacity-04 font-size-16" nz-icon nzType="lock" theme="outline"></i>
                            <span class="m-l-10">Account Setting</span>
                        </div>
                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                    </a>
                </li>
                <li nz-menu-item>
                    <a class="p-v-5 d-flex align-items-center justify-content-between">
                        <div>
                            <i class="opacity-04 font-size-16" nz-icon nzType="project" theme="outline"></i>
                            <span class="m-l-10">Projects</span>
                        </div>
                        <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
                    </a>
                </li> -->
            <li nz-menu-item (click)="logout()">
              <a class="p-v-5 d-flex align-items-center justify-content-between">
                <div>
                  <i class="opacity-04 font-size-16" nz-icon nzType="logout" theme="outline"></i>
                  <span class="m-l-10">Logout</span>
                </div>
                <i class="font-size-10" nz-icon nzType="right" theme="outline"></i>
              </a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </li>
    </ul>
  </div>
</div>