import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'master',
        loadChildren: () => import('../../master-data/master-data.module').then(m => m.MasterDataModule),
    } 
];