import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
  {
    path: 'dashboard',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: [],
  },
  {
    path: '#',
    title: 'Master Data',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'database',
    submenu: [
      {
        path: '/master/users',
        title: 'Users',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: '',
        submenu: [],
      },
      {
        path: '/master/locations',
        title: 'Locations',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: '',
        submenu: [],
      },
    ],
  },
];
